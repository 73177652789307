<template>
  <div>
    <footer class="switch-bg-dblue py-5 mt-5">
      <div class="container">
        <div class="row pt-5 ">
          <div class="col-lg-3 col-md-8 mb-lg-3 mb-5">
            <h5>Chas Everitt Luxury</h5>
            <p>At Chas Everitt Luxury, we provide an unrivalled collection of South Africa’s most prestigious properties. Buy
              or sell your luxury property with us. </p>
              <center><a class="btn btn-primary switch-bg-white switch-blue text-end" data-bs-toggle="modal" data-bs-target="#signUpModal">
                Newsletter signup
              </a></center>

            <!-- Modal -->
            <div class="modal fade" id="signUpModal"  aria-labelledby="signUpModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="signUpModalLabel">Newsletter signup</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <form id="newsletterForm" @submit.prevent="validateForm">
                      <div class="mb-3 col-md-6">
                        <label for="name" class="form-label">Name</label>
                        <input v-model="formData.name" type="text" class="form-control" id="name" aria-describedby="name">
                        <span class="error" v-if="errors.name">{{ errors.name }}</span>
                      </div>
                      <div class="mb-3 col-md-6">
                        <label for="surname" class="form-label">Surname</label>
                        <input v-model="formData.surname" type="text" class="form-control" id="surname" aria-describedby="surname">
                        <span class="error" v-if="errors.surname">{{ errors.surname }}</span>
                      </div>
                      <div class="mb-3 col-md-6">
                        <label for="email" class="form-label">Email address</label>
                        <input v-model="formData.email" type="text" class="form-control" id="email" aria-describedby="email">
                        <span class="error" v-if="errors.email">{{ errors.email }}</span>
                      </div>
                      <div class="mb-3 col-md-6">
                        <label for="cellNumber" class="form-label">Cell Number</label>
                        <input v-model="formData.cellNumber" type="text" class="form-control" id="cellNumber" aria-describedby="cellNumber">
                        <span class="error" v-if="errors.cellNumber">{{ errors.cellNumber }}</span>
                      </div>
                      <div class="mb-3 form-check">
                        <input v-model="formData.acceptPrivacy" type="checkbox" class="form-check-input" id="acceptPrivacy">
                        <label class="form-check-label" for="acceptPrivacy">I accept your privacy terms. <a href="https://www.chaseveritt.co.za/privacy-policy/" class="switch-ddblue" target="_blank">Privacy Policy</a></label>
                        <span class="error" v-if="errors.acceptPrivacy">{{ errors.acceptPrivacy }}</span>
                      </div>
                      <button type="submit" class="btn btn-primary switch-bg-dblue" :disabled="!formData.acceptPrivacy">Submit</button>

                      <div id="errorContainer">
                        <span class="error" v-if="errors.serverError">{{ errors.serverError }}</span>
                        <span class="success" v-if="successMessage">{{ successMessage }}</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <!-- End of Modal -->
          </div>
          


          <div class="col-lg-2 col-md-5 offset-lg-1 mb-3">
            <h5>Important links</h5>
            <ul class="nav flex-column mt-3">
              <li class="nav-item mb-3"><a href="https://www.chaseveritt.co.za/disclaimer-policy-website/" target="_blank" class="nav-link p-0 ">Terms, conditions and disclaimers</a></li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-5 mb-3">
            <h5>Contact us</h5>
            <ul class="nav flex-column mt-3">
              <li class="nav-item mb-3"><a id="call-footer" href="tel:+27 11 100 8610" class="nav-link p-0">+27 11 100 8610</a></li>
              <li class="nav-item mb-3"><a id="email-footer" href="mailto:luxuryenquiries@everitt.co.za" class="nav-link p-0 ">luxuryenquiries@everitt.co.za</a></li>
              <li class="nav-item mb-3">
                <div class="row g-md-0">
                  <div class="col-2"><a href="https://www.facebook.com/ChasEverittLuxury" target="_blank"><img src="/images/icons/facebook.png" class="mw-100"
                        width="20px" alt="Chas Everitt Facebook"></a></div>
                  <div class="col-2"><a href="https://www.linkedin.com/company/chas-everitt/?trk=top_nav_home" target="_blank"><img src="/images/icons/linkedin.png" class="mw-100"
                        width="20px" alt="Chas Everitt LinkedIn"></a></div>
                  <div class="col-2"><a href="https://www.instagram.com/chaseveritt_luxury/" target="_blank"><img src="/images/icons/instagram.png" class="mw-100"
                        width="20px" alt="Chas Everitt Instagram"></a></div>
                  <div class="col-2"><a href="https://www.youtube.com/@ChasEverittSA" target="_blank"><img src="/images/icons/youtube.png" class="mw-100"
                        width="20px" alt="Chas Everitt YouTube"></a></div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-lg-3  mb-3 ps-lg-5 ms-lg-auto">
            <h5>Chas Everitt International</h5>
            <p>A leading real estate company.</p>
            <p>For almost half a century, Chas Everitt has made a lasting impact on the lives of thousands by helping
              families move toward their dreams.</p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row ">
              <div class="col-md-2 col-3">
                  <a href="https://www.leadingre.com/" target="_blank"><img src="/images/leading-real-estate.png" class="mw-100" alt="Leading - Real Estate Companies of the world"></a>
              </div>
              <div class="col-md-2 offset-md-3 offset-1 col-3 align-self-center">
                <a href="https://www.chaseveritt.co.za/" target="_blank"><img src="/images/chas-everitt-white.png" class="w-100" alt="Chas Everitt Logo"></a>
            </div>
            <div class="col-md-2 col-3 ms-auto align-self-center">
              <a href="https://www.luxuryportfolio.com/" target="_blank"><img src="/images/chas-luxury-logo.png" class="mw-100" alt="Luxury Portfolio International"></a>
            </div>
          </div>
          <div class="row justify-content-center">
          <div class="col-12 text-center">
            <hr class="switch-border-white mb-2 mt-4">
            <p class="mt-4">Copyright &copy; 2023 Chas Everitt International Property Group</p>
            <p class="mb-4">First Realty Central Pty Ltd TA Chas Everitt International Property Group is a Franchisor of the Chas Everitt International Property Group. We are registered with the PPRA.</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      formData: {
        name: '',
        surname: '',
        cellNumber: '',
        email: '',
        acceptPrivacy: false,
      },
      errors: {},
      successMessage: '',
    };
  },
  methods: {
    validateForm() {
      console.log('validateForm method called');
      this.errors = {};
      this.successMessage = '';

      // Validate form fields
      if (!this.formData.name) {
        this.errors.name = 'Name is required';
      }

      if (!this.formData.surname) {
        this.errors.surname = 'Surname is required';
      }

      if (!this.formData.cellNumber) {
        this.errors.cellNumber = 'Phone is required';
      } else if (!/^\d+$/.test(this.formData.cellNumber)) {
        this.errors.cellNumber = 'Phone must contain only numeric characters';
      }

      if (!this.formData.email) {
        this.errors.email = 'Email is required';
      }
      if (!this.formData.acceptPrivacy) {
        this.errors.acceptPrivacy = 'Please accept the privacy terms';
        return;
      }
      if (Object.keys(this.errors).length > 0) {
        return;
      }
      axios.post('https://stratitude.co.za/api/luxuryNewsletter.php', this.formData)
        .then(response => {
          this.successMessage = 'Email sent successfully';
          this.formData.name = '';
          this.formData.surname = '';
          this.formData.cellNumber = '';
          this.formData.email = '';
          this.formData.acceptPrivacy = false;
        })
        .catch(error => {
          this.errors.serverError = 'Error sending email';
        });
    },
  },
};

</script>

<style scoped>
.error{font-size: 14px;color: #e00134; display: block;}
.success{font-size: 14px;color: #28a745; display: block;}
</style>
