<template>
  <div>
    <div v-for="item in originalListings" v-if="!loading" class="mb-5">
      <section id="" class="mt-5" v-if="item.payload.listing.is_featured_property">
        <div class="container-fluid p-0">
          <div class="row justify-content-center g-0">
            <div class="col-12">
              <div class="switch-bg-lgrey">
                <div class="row g-0">
                  <div class="col-lg-6">
                    <div class="featured-img py-4 px-lg-5 px-4">
                      <h3><i class="bi bi-star-fill"></i> Featured property</h3>
                      <img :src="item.payload.listing.featured_image" class="mw-100" style="border-top-right-radius: 30px;" />
                    </div>
                  </div>
                  <div class="col-lg-6 align-self-center px-4">
                    <div class="featured-block">
                      <h3>{{ item.payload.listing.data.listing[0].marketing_heading }}</h3>
                      <h4 class="thin">{{ item.payload.listing.data.listing[0].loc_suburb }}, {{ item.payload.listing.data.listing[0].loc_region }}</h4>
                      <h1 class="text-start my-1">{{ formatCurrency(item.payload.listing.data.listing[0].price) }}</h1>
                      <hr>
                      <p>{{ item.payload.listing.ai_description }}</p>
                      <div class="cta-block">
                        <div class="row g-0">
                          <div class="col-sm-5 col-9 order-sm-1 order-2">
                            
                          </div>
                          <div class="col-sm-6 text-sm-end align-self-end offset-sm-1 pb-lg-0 pb-3 order-sm-2 order-1">
                            <a class="btn btn-primary switch-bg-dblue text-end" :href="'/property/' + item.payload.listing.data.listing[0].id" role="button">FIND OUT MORE</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Menu from '@/components/home/Menu.vue'
import Footer from '@/components/home/Footer.vue'

export default {
  components: {
    Menu, Footer
  },
  data() {
    return {
      listings: [],
      loading: true,
      selectedType: null,
      selectedLocation: null,
      maxPrice: 100000000,
      originalListings: [],
    }
  },
  mounted() {
    this.fetchListings();
  },
  computed: {
    formattedDescription() {
      if (this.listings.length > 0) {
        const rawDescription = this.listings[0].payload.listing.ai_description;
        const sentences = rawDescription.split('. ');
        const paragraphs = [];
        let currentParagraph = '';

        for (let i = 0; i < sentences.length; i++) {
          currentParagraph += sentences[i];
          if ((i + 1) % 3 === 0 || i === sentences.length - 1) {
            currentParagraph += '.';
            paragraphs.push(currentParagraph.trim());
            currentParagraph = '';
          } else {
            currentParagraph += '. ';
          }
        }
        return paragraphs;
      }
      return [];
    }
  },
  methods: {
    fetchListings: async function () {
      this.loading = true
      await axios
        .get('https://luxury-dev.chaseveritt.co.za/api/fetch_listings.php', {
          headers: {
            'Accept': 'application/json',
          }
        })
        .then(response => {
          this.originalListings = response.data
          this.listings = this.originalListings
        })
        .catch(error => {
          console.log(error)
        });
      this.loading = false
    },
    toggleType(type) {
      this.selectedType = this.selectedType === type ? null : type
      this.filterListings()
    },

    toggleLocation(location) {
      this.selectedLocation = this.selectedLocation === location ? null : location
      this.filterListings()
    },
    filterListings() {
      if (this.selectedType || this.selectedLocation) {
        this.listings = this.originalListings.filter(listing => {
          console.log(listing)
          const listingTags = listing.payload.listing.tags
          const typeFilter = !this.selectedType || listingTags[this.selectedType]
          const locationFilter = !this.selectedLocation || listingTags[this.selectedLocation]
          return typeFilter && locationFilter
        })
      } else {
        this.listings = this.originalListings
      }
    },
  }
}
</script>
<style scoped>
.force-height {
  height: 300px; /* Change 100px to the desired height value */
  object-fit: cover;
  /* Other CSS
 properties, if needed */
}
</style>

